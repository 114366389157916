import React from 'react';
import { EcommerceSaleByGender, EcommerceYearlySales } from 'src/components/_dashboard/general-ecommerce';
import { Container, Grid } from '@material-ui/core';
import useSettings from 'src/hooks/useSettings';
import Page from 'src/components/Page';

export const Raporlar = () => {
  const { themeStretch } = useSettings();

  return (
    <Page title="Raporlar | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <EcommerceSaleByGender />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <EcommerceYearlySales />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
