import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { styled } from '@material-ui/core/styles';
import {
  TextField,
  Typography,
  Stack,
  Container,
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Switch,
  Box,
  Button
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import Page from '../../../components/Page';
import useSettings from 'src/hooks/useSettings';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useParams } from 'react-router';
import {  useEffect, useState } from 'react';
import mockData from 'src/utils/mock-data';
import { random } from 'lodash';
import Label from 'src/components/Label';
import { useTheme } from '@emotion/react';
import { BiTrash } from 'react-icons/bi';
import { useSnackbar } from 'notistack5';
import { v4 as uuidv4 } from 'uuid';


const RootStyle = styled('div')(({ theme }) => ({
  margin: theme.spacing(3),
  padding: theme.spacing(3)
}));
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));
// ----------------------------------------------------------------------

export default function SiparisOlustur() {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [addNewUser, setAddNewUser] = useState(false);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const ReviewSchema = Yup.object().shape({
    name: Yup.string().required('Ad tanımlamak zorunludur.')
  });
  const [addProducts, setAddProducts] = useState([]);
  const flattenData = (data, result = []) => {
    data.forEach((item) => {
      result.push(item);
      if (item.children && item.children.length > 0) {
        flattenData(item.children, result);
      }
    });
    return result;
  };
  const productsData = [...Array(10)].map((_, index) => ({
    id: mockData.id(index),
    product: `Ürün ${random(100)}`,
    price: random(999)
  }));
  const userData = [...Array(10)].map((_, index) => ({
    id: mockData.id(index),
    name: mockData.name.firstName(index),
    surname: mockData.name.lastName(index),
    email: mockData.email(index),
    phone: mockData.phoneNumber(index),
    county: 'İstanbul',
    district: 'Ataşehir',
    street: 'Küçükbakkalköy',
    address: mockData.address.fullAddress(index)
  }));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: '',
      source: '',
      price: '',
      county: '',
      district: '',
      street: '',
      address: '',
      name: '',
      surname: '',
      email: '',
      phone: '',
      piece: 1,
      efatura: false,
      pay: false
    },
    validationSchema: ReviewSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setAddProducts((val) => [...val, values]);
    }
  });

  const { errors, touched, resetForm, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;
  useEffect(() => {
    setFieldValue('price', values.product?.price);
    setFieldValue('priceView', values.product?.price);
    setFieldValue('id', uuidv4());

  }, [values.product]);

  const handleAddUser = (val) => {
    setFieldValue('name', val.name);
    setFieldValue('surname', val.surname);
    setFieldValue('address', val.address);
    setFieldValue('county', val.county);
    setFieldValue('district', val.district);
    setFieldValue('email', val.email);
    setFieldValue('phone', val.phone);
    setFieldValue('street', val.street);
    setFieldValue('name', val.name);
  };
  const saveProducts = () => {
    enqueueSnackbar('Kayıt Başarıyla Eklendi', { variant: 'success' });
    setAddProducts([])
  }
  const handleDeleteProduct = (row) => {
      const newProduct = [...addProducts]
      const filteredProducts = newProduct.filter(np => np.id !== row.id)
      setAddProducts(filteredProducts)
  }
  return (
    <Page title={!edit ? 'Sipariş Ekle | Ekonokobi' : 'Sipariş Düzenle | Ekonokobi'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!edit ? 'Sipariş Ekle' : 'Sipariş Düzenle'}
          links={[
            { name: 'Sipariş Yönetimi', href: PATH_DASHBOARD.siparisyonetimi.siparisler },
            {
              name: 'Sipariş Ekle'
            }
          ]}
        />

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <FormControl fullWidth>
                      <Autocomplete
                        getOptionLabel={(option) => option.product}
                        filterSelectedOptions
                        onChange={(event, newValue) => {
                          setFieldValue('product', newValue);
                        }}
                        value={values.product}
                        renderInput={(params) => <TextField {...params} label="Ürünler" placeholder="Ürünler" />}
                        options={productsData}
                        isOptionEqualToValue={productsData.id}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="source-select">Kaynak</InputLabel>
                      <Select
                        labelId="source-select"
                        id="source-s"
                        value={values.source}
                        label="Kaynak"
                        {...getFieldProps('source')}
                        error={Boolean(touched.source && errors.source)}
                        helperText={touched.source && errors.source}
                      >
                        <MenuItem value={'Instagram'}>Instagram</MenuItem>
                        <MenuItem value={'Facebook'}>Facebook</MenuItem>
                        <MenuItem value={'Whatsapp'}>Whatsapp</MenuItem>
                        <MenuItem value={'Site'}>Site</MenuItem>
                        <MenuItem value={'Hepsi Burada'}>Hepsi Burada</MenuItem>
                        <MenuItem value={'Trendyol'}>Trendyol</MenuItem>
                        <MenuItem value={'N11'}>N11</MenuItem>
                        <MenuItem value={'Pazarama'}>Pazarama</MenuItem>
                        <MenuItem value={'PTTAVM'}>PTTAVM</MenuItem>
                      </Select>
                    </FormControl>
                    <Grid container>
                      <Grid item md={6} sx={{ pr: 1 }}>
                        <TextField
                          fullWidth
                          label="Fiyat *"
                          {...getFieldProps('price')}
                          error={Boolean(touched.price && errors.price)}
                          helperText={values.priceView && `Sistem Fiyat ${values.priceView} ₺`}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          fullWidth
                          label="Adet"
                          {...getFieldProps('piece')}
                          error={Boolean(touched.piece && errors.piece)}
                        />
                      </Grid>
                    </Grid>
                    <Label sx={{ p: 2 }}>Kişisel Bilgiler</Label>
                    <Grid container>
                      {!addNewUser && (
                        <FormControl fullWidth>
                          <Autocomplete
                            getOptionLabel={(option) => `${option.name} ${option.surname}`}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField {...params} label="Kullanıcılar" placeholder="Kullanıcılar" />
                            )}
                            options={userData}
                            onChange={(e, val) => handleAddUser(val)}
                            isOptionEqualToValue={userData.id}
                          />
                        </FormControl>
                      )}
                      <FormControlLabel
                        control={<Switch checked={addNewUser} onChange={(e) => setAddNewUser(e.target.checked)} />}
                        label="Yeni Kullanıcı Ekle"
                      />
                    </Grid>
                    {addNewUser && (
                      <Grid container>
                        <Grid md={6} item sx={{ mb: 2, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="Ad *"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Grid>
                        <Grid md={6} item sx={{ mb: 2, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="Soyad *"
                            {...getFieldProps('surname')}
                            error={Boolean(touched.surname && errors.surname)}
                            helperText={touched.surname && errors.surname}
                          />
                        </Grid>
                        <Grid md={6} item sx={{ mb: 2, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="E-Posta *"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid md={6} item sx={{ mb: 2, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="Telefon *"
                            {...getFieldProps('phone')}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                          />
                        </Grid>
                        <Grid md={6} item sx={{ mb: 2, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="İl *"
                            {...getFieldProps('county')}
                            error={Boolean(touched.county && errors.county)}
                            helperText={touched.county && errors.county}
                          />
                        </Grid>
                        <Grid md={6} item sx={{ mb: 2, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="İlçe *"
                            {...getFieldProps('district')}
                            error={Boolean(touched.district && errors.district)}
                            helperText={touched.district && errors.county}
                          />
                        </Grid>
                        <Grid md={6} item sx={{ mb: 0, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="Mahalle *"
                            {...getFieldProps('street')}
                            error={Boolean(touched.street && errors.street)}
                            helperText={touched.street && errors.county}
                          />
                        </Grid>
                        <Grid md={6} item sx={{ mb: 0, pr: 1 }}>
                          <TextField
                            fullWidth
                            label="Adres *"
                            {...getFieldProps('address')}
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.county}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Label sx={{ p: 2 }}>Ödeme Bilgileri</Label>
                    <Grid container spacing={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...getFieldProps('pay')}
                            checked={values.pay}
                            onChange={(e) => {
                              setFieldValue('pay', e.target.checked);
                            }}
                          />
                        }
                        label="Ödeme Alındı"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...getFieldProps('efatura')}
                            checked={values.efatura}
                            onChange={(e) => {
                              setFieldValue('efatura', e.target.checked);
                            }}
                          />
                        }
                        label="E-Fatura Kesildi"
                      />
                    </Grid>
                    <Stack direction="row" justifyContent="flex-end">
                      <LoadingButton
                        sx={{ paddingLeft: 3, paddingRight: 3 }}
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Kaydet
                      </LoadingButton>
                    </Stack>
                    <Stack>
                      {addProducts.length > 0 && (
                        <Stack>
                          <Label sx={{ p: 2, mb: 2 }}>Ürünler</Label>
                          <Grid container spacing={3}>
                            <Grid item xs={2}>
                              <InputLabel>Ad Soyad</InputLabel>
                            </Grid>
                            <Grid item xs={2}>
                              <InputLabel>Ürün</InputLabel>
                            </Grid>
                            <Grid item xs={2}>
                              <InputLabel>Fiyat</InputLabel>
                            </Grid>
                            <Grid item xs={2}>
                              <InputLabel>Kaynak</InputLabel>
                            </Grid>
                            <Grid item xs={2}>
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Box>
                                  <InputLabel>Ödeme</InputLabel>
                                </Box>
                                <Box>
                                  <InputLabel>E-Fatura</InputLabel>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Stack>
                      )}
                      {addProducts.map((product) => {
                        return (
                          <Grid container spacing={3} sx={{ mt: 1, alignItems: 'center',pb:1 }}>
                            <Grid item xs={2}>
                              <Box>
                                {product.name} {product.surname}
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box>{product.product.product}</Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box>{product.price} ₺</Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box>{product.source}</Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Box>
                                  <Label
                                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                    color={(!product.pay === 'banned' && 'error') || 'success'}
                                  >
                                    {product.pay ? 'Ödendi' : 'Ödenmedi'}
                                  </Label>
                                </Box>
                                <Box>
                                  {
                                    <Label
                                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                      color={(!product.efatura && 'error') || 'success'}
                                    >
                                      {product.efatura ? 'Kesildi' : 'Kesilmedi'}
                                    </Label>
                                  }
                                </Box>
                                <Button onClick={() => handleDeleteProduct(product)} variant="contained" color="error">
                                  <BiTrash />
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        );
                      })}
                      {addProducts.length > 0 && (
                        <Stack direction="row" justifyContent="flex-end" sx={{mt:1}}>
                          <Button onClick={() => saveProducts()} variant="contained" color="primary">
                            Onayla
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
