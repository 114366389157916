import { filter, random, sample } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getUserList, deleteUser } from '../../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user/list';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import editFill from '@iconify/icons-eva/edit-fill';
import { useSnackbar } from 'notistack5';
import mockData from 'src/utils/mock-data';
import moment from "moment/dist/moment";
import "moment/dist/locale/tr";
moment.locale("tr");

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Kampanya Adı', alignRight: false },
  { id: 'code', label: 'Kampanya Kodu', alignRight: false },
  { id: 'olusturma', label: 'Oluşturulma Tarihi', alignRight: false },
  { id: 'bitis', label: 'Bitiş Tarihi', alignRight: false },
  { id: 'sayi', label: 'Kullanim Sayisi', alignRight: true },
  { id: 'status', label: 'Durum', alignRight: false },
  { id: 'actions', label: 'İşlemler', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function Kampanyalar() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userList } = useSelector((state) => state.user);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar } = useSnackbar();
  const [promotoList,setPromotoList] = useState([...Array(24)].map((_, index) => ({
    id: mockData.id(index),
    name: mockData.name.fullName(index),
    code:`EKNKB ${  random(100)}`,
    start: mockData.time(1),
    end: mockData.time(1),
    kullanimSayisi: random(100),
    status: sample([true,false]) || true,
  })))
  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = promotoList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (userId) => {
  
    dispatch(deleteUser(userId));
    enqueueSnackbar('Kayıt Başarıyla Silindi', { variant: 'success' });

  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - promotoList.length) : 0;

  const filteredUsers = applySortFilter(promotoList, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Kampanyalar | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Kampanyalar"
          links={[{ name: 'Anasayfa', href: PATH_DASHBOARD.root }, { name: 'Kampanyalar' }]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.uyeyonetimi.uyegrubuekle}
              startIcon={<Icon icon={plusFill} />}
            >
              Yeni Üye Grubu
            </Button>
          }
        />

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={promotoList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name,  status,  start, end,code,kullanimSayisi } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} selected={isItemSelected} aria-checked={isItemSelected}>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{code}</TableCell>

                        <TableCell align="left">{moment(start).format("LLL")}</TableCell>
                        <TableCell align="left">{moment(end).format("LLL")}</TableCell>
                        <TableCell align="center">{kullanimSayisi}</TableCell>

                        <TableCell align="left">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={!status ? 'error' : 'success'}
                          >
                            {status ? 'Aktif' : 'Pasif'}
                          </Label>
                        </TableCell>

                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button onClick={() => handleDeleteUser(row.id)} variant="outlined" color="error">
                              <Icon icon={trash2Outline} width={14} height={14} />
                            </Button>
                            <Button variant="outlined" color="info">
                              <Icon icon={editFill} width={14} height={14} />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
