import axios from "axios";

export const useApi =  () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers:{
      "apikey":"123123"
    }
  });
};
export const useApiFile =  () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers:{
      "Content-Type": "multipart/form-data",
      "apikey":"123123"

    }
  });
};