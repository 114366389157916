import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
// routes
// hooks
// components
import { useApi } from 'src/utils/api';
import { useSnackbar } from 'notistack5';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { ProductListHead, ProductListToolbar, ProductMoreMenu } from 'src/components/_dashboard/e-commerce/product-list';
import Label from 'src/components/Label';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Ürünler', alignRight: false },
  { id: 'brand', label: 'Marka', alignRight: false },
  { id: 'category', label: 'Kategori', alignRight: false },
  { id: 'price', label: 'Fiyat', alignRight: true },
  { id: 'status', label: 'Durum', alignRight: true },

  { id: '' }
];

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 64,
  height: 64,
  objectFit: 'cover',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadiusSm
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_product) => _product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);
}

const currencies = [
  {
    id: 1,
    name: 'TL',
    symbol: '₺'
  },
  {
    id: 2,
    name: 'USD',
    symbol: '$'
  },
  {
    id: 3,
    name: 'EUR',
    symbol: '€'
  }
];

function currencySymbol(id) {
  const c = currencies.find((c) => c.id === id);
  return c.symbol;
}
// ----------------------------------------------------------------------

export default function Urunler() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [openDeleteHandle, setOpenDeleteHandle] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  const getProducts = () => {
    useApi()
      .get('product')
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteProduct = (productId) => {
    useApi().delete("product",{data : {id:productId}}).then((res) => {
      setOpenDeleteHandle(false);
      enqueueSnackbar('Kayıt Başarıyla Silindi', { variant: 'success' });
      getProducts()
    }).catch((err) => {
      enqueueSnackbar('Kayıt silinirken bir hata ile karşılaşıldı', { variant: 'error' });
      setOpenDeleteHandle(false);
      console.log(err)
    })
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  const filteredProducts = products.length > 0 ? applySortFilter(products, getComparator(order, orderBy), filterName) : [];

  const isProductNotFound = filteredProducts.length === 0;
  

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <Page title="Ürünler | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Ürün Listesi"
          links={[
            { name: 'Anasayfa', href: PATH_DASHBOARD.root },
            {
              name: 'Ürünler',
              href: PATH_DASHBOARD.eCommerce.root
            },
            { name: 'Ürün Listesi' }
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.urunler.tanimlamalar}
              startIcon={<Icon icon={plusFill} />}
            >
              Yeni Ürün Ekle
            </Button>
          }
        />

        <Card>
          <ProductListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={products.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    let { id, category, brand, name, image, price, status, price_type } = row;
                    brand = JSON.parse(brand);
                    category = JSON.parse(category);
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" align="left" style={{ paddingLeft: 0 }}>
                          <Box
                            sx={{
                              py: 1,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <ThumbImgStyle
                              alt={name}
                              src={'http://localhost:3000/static/mock-images/products/product_24.jpg'}
                            />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{brand.name}</TableCell>
                        <TableCell>{category.name}</TableCell>
                        <TableCell align="right">
                          {price} {currencySymbol(price_type)}{' '}
                        </TableCell>
                        <TableCell align="right">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={(status === 1 && 'primary') || (status === 0 && 'error')}
                          >
                            {(status === 1 && 'Aktif') || (status === 0 && 'Pasif')}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <ProductMoreMenu
                            onDelete={() => handleDeleteProduct(id)}
                            openDeleteHandle={openDeleteHandle}
                            setOpenDeleteHandle={setOpenDeleteHandle}
                            productId={id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isProductNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Box sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
