import React from 'react';
import { Icon } from '@iconify/react';
import {  styled } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useApi } from 'src/utils/api';

// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Box,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  CloudUploadIcon,
  TableHead,
  TableSortLabel
} from '@material-ui/core';
// redux
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { UserMoreMenu } from '../components/_dashboard/user/list';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'İsim', alignRight: false },
  { id: 'tittle', label: 'Başlık', alignRight: false },
  { id: 'description', label: 'Açıklama', alignRight: false },
  { id: 'keyword', label: 'Anahtar Kelimeler', alignRight: false },
  { id: 'slug', label: 'Slug', alignRight: false },
  { id: 'edit', label: 'Düzenle', alignRight: false }
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 740,
  bgcolor: 'background.paper',
  borderRadius: '12px',
  border: 'none',
  boxShadow: 24,
  p: 4
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export default function Categories() {
  const { themeStretch } = useSettings();
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');

  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState({
    name: '',
    title: '',
    description: '',
    keywords: '',
    slug: 'sdfgsdgf',
    banner_image: 'asdfasdf'
  });
  const getAllCategories = () => {
    useApi()
      .get('blog/category')
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);
  // GET

  const handleClick = () => {
    useApi()
      .post('blog/category', values)
      .then((res) => {
        toast.success('Kategori başarıyla eklendi.');
        getAllCategories();
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Kategori eklenirken bir hata ile karşılaşıldı');
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setValues((val) => ({ ...val, [name]: value }));
  };
  const handleDeleteCategory = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      })
      .then((result) => {
        if (result.isConfirmed) {
          useApi()
          .delete('blog/category', { id: id })
    
          .then((res) => {
            toast.success('Kategori başarıyla silindi.');
            getAllCategories();
          })
          .catch((err) => {
            console.log(err);
            toast.error('Kategori silinirken bir hata ile karşılaşıldı');
          });
        }
      });
    
  };
  return (
    <Page title="Kategoriler | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Kategoriler"
          links={[
            { name: 'Anasayfa', href: PATH_DASHBOARD.root },
            { name: 'Blog', href: PATH_DASHBOARD.category },
            { name: 'Kategoriler' }
          ]}
          action={
            <Button onClick={handleOpen} variant="contained" startIcon={<Icon icon={plusFill} />}>
              Yeni Kategori Ekle
            </Button>
          }
        />
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <FormControl fullWidth>
                <TextField
                  id="standard-basic"
                  label="İsim"
                  variant="outlined"
                  style={{ width: '100%', marginBottom: '20px' }}
                  value={values.name}
                  name="name"
                  onChange={(e) => handleChangeValue(e)}
                />
              </FormControl>
              <TextField
                id="standard-basic"
                label="Başlık"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                value={values.title}
                name="title"
                onChange={(e) => handleChangeValue(e)}
              />
              <TextField
                id="standard-basic"
                label="Açıklama"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                value={values.description}
                name="description"
                onChange={(e) => handleChangeValue(e)}
              />
              <TextField
                id="standard-basic"
                label="Slug / URL"
                variant="outlined"
                style={{ width: '100%', marginBottom: '20px' }}
                value={values.keywords}
                name="keywords"
                onChange={(e) => handleChangeValue(e)}
              />
              <Stack spacing={3} style={{ width: '100%', marginBottom: '20px' }}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={top100Films}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Anahtar Kelimeler" placeholder="Anahtar Kelimeler" />
                  )}
                />
              </Stack>
              <Button
                style={{ width: '100%', marginBottom: '20px', padding: '30px' }}
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
              >
                <span style={{ fontSize: '25px', marginRight: '5px' }}>+</span> Resim Yükle
                <VisuallyHiddenInput type="file" />
              </Button>
              <Button
                onClick={handleClick}
                variant="contained"
                style={{ width: '100%', marginBottom: '20px', padding: '10px' }}
              >
                Kaydet
              </Button>
            </Box>
          </Modal>
        </div>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon>{headCell.label}</TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((category, id) => {
                    const { name, title, description, keywords } = category;
                    return (
                      <TableRow>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              alt={name}
                              src={'http://localhost:3000/static/mock-images/products/product_24.jpg'}
                            />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{title}</TableCell>
                        <TableCell align="left">{description}</TableCell>
                        <TableCell align="left">{keywords}</TableCell>
                        <TableCell align="left">{category.slug}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu onDelete={() => handleDeleteCategory(id)} userName={name} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {categories.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
const top100Films = [
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 }
];
