import React from 'react';
import { ThemeProvider, alpha, createTheme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  Container,
  TextField,
  Button,
  Stack,
  Modal,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  PropTypes,
  Tabs,
  Tab,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: ' none',
  boxShadow: 24,
  p: 4
};
const customTheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#ba000d', // Sadece primary rengin dark tonunu değiştiriyoruz
      contrastText: '#fff'
    }
  }
});
export const EpostaPazarlama = () => {
  const [value, setValue] = React.useState('1');
  const handleChange3 = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Container>
      <h3>E-Posta Araçları / Bülten Listesi</h3>
      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Item>
              <TextField id="outlined-basic" label="Ara" variant="outlined" style={{ width: '100%' }} />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <Stack spacing={2} direction="row">
                <Button variant="contained" style={{ width: '100%', padding: '14px' }} onClick={handleOpen}>
                  Yeni Kayıt
                </Button>
                <Button
                  style={{ width: '100%', padding: '14px', color: 'white', backgroundColor: '#ff4c9e ' }}
                  onClick={handleOpen1}
                >
                  Excel İşlemleri
                </Button>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h4 style={{ marginBottom: '20px' }}>Yeni Bülten Kaydı</h4>
            <TextField
              id="outlined-basic"
              label="E-Posta Adresi"
              variant="outlined"
              style={{ width: '100%', marginBottom: '20px' }}
            />
            <TextField
              id="outlined-basic"
              label="Adı"
              variant="outlined"
              style={{ width: '100%', marginBottom: '20px' }}
            />
            <FormControl sx={{ minWidth: '100%', marginBottom: '20px' }}>
              <InputLabel id="demo-simple-select-autowidth-label">Bülten Grubu</InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={age}
                onChange={handleChange}
                autoWidth
                label="Bülten Grubu"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Twenty</MenuItem>
                <MenuItem value={21}>Twenty one</MenuItem>
                <MenuItem value={22}>Twenty one and a half</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: '100%', marginBottom: '20px' }}>
              <InputLabel id="demo-simple-select-autowidth-label">Dil</InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={age}
                onChange={handleChange}
                autoWidth
                label="Dil"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Twenty</MenuItem>
                <MenuItem value={21}>Twenty one</MenuItem>
                <MenuItem value={22}>Twenty one and a half</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" style={{ width: '100%', padding: '14px' }}>
              Kaydet
            </Button>
          </Box>
        </Modal>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h4 style={{ marginBottom: '20px' }}>Hediye Çekleri - Excel İşlemleri</h4>

            <Box sx={{ width: '100%', typography: 'body1', marginBottom: '50px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange3} aria-label="lab API tabs example">
                    <Tab label="Dışarı Aktar" value="1" />
                    <Tab label="İçeri Aktar" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1" style={{ marginTop: '20px' }}>
                  Tüm veriler excel olarak dışarı aktarılacaktır.
                </TabPanel>
                <TabPanel value="2" style={{ marginTop: '20px' }}>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                      <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Work" secondary="Jan 7, 2014" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Vacation" secondary="July 20, 2014" />
                    </ListItem>
                  </List>
                </TabPanel>
              </TabContext>
            </Box>

            <Button variant="contained" style={{ width: '100%', padding: '14px' }}>
              Kaydet
            </Button>
          </Box>
        </Modal>
      </div>
    </Container>
  );
};
