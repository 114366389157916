import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import SiparisAyarlari from 'src/pages/dashboard/siparisYonetimi/SiparisAyarlari';
import TerkEdilmisSepetler from 'src/pages/dashboard/siparisYonetimi/TerkEdilmisSepetler';
import Siparisler from 'src/pages/dashboard/siparisYonetimi/Siparisler';
import SiparisOlustur from 'src/pages/dashboard/siparisYonetimi/SiparisOlustur';
import { Uyeler } from 'src/pages/dashboard/urunYonetimi/Uyeler';
import { UyeGruplari } from 'src/pages/dashboard/urunYonetimi/UyeGruplari';
import { UyeAyarlari } from 'src/pages/dashboard/urunYonetimi/UyeAyarlari';
import { HediyeCeki } from 'src/pages/dashboard/pazarlama/HediyeCeki';
import { Kampanyalar } from 'src/pages/dashboard/pazarlama/Kampanyalar';
import { EpostaPazarlama } from 'src/pages/dashboard/pazarlama/EpostaPazarlama';
import { SmsPazarlama } from 'src/pages/dashboard/pazarlama/SmsPazarlama';
import Categories from 'src/pages/Categories';
import Urunler from 'src/pages/dashboard/urunler/Urunler';
import UyeGrubuEkle from 'src/pages/dashboard/urunYonetimi/UyeGrubuEkle';
import { Raporlar } from 'src/pages/dashboard/Raporlar';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/app" replace /> },
        { path: 'app', element: <GeneralEcommerce /> },
        {
          path: 'urunler',
          children: [
            { path: '/', element: <Navigate to="/urunler/urunler" replace /> },
            { path: 'urunler', element: <Urunler /> },
            { path: 'tanimlamalar', element: <Tanimlamalar /> },
            { path: 'tanimlamalar/:id/edit', element: <Tanimlamalar /> },
            { path: 'toplu-urun-islemleri', element: <TopluUrunIslemleri /> },
            { path: 'yeni-kullanici', element: <YeniKullanici /> },
            { path: 'urun-ayarlari', element: <UrunAyarlari /> },
            { path: 'varyantlar', element: <Varyantlar /> },
            { path: 'varyant-tanimla', element: <VaryantTanimla /> },
            { path: 'varyant-tanimla/:id', element: <VaryantTanimla /> },
            { path: 'kategoriler', element: <Kategoriler /> },
            { path: 'kategori-ekle', element: <KategoriEkle /> },
            { path: 'kategori-ekle/:id', element: <KategoriEkle /> },
            { path: 'markalar', element: <Markalar /> },
            { path: 'marka-ekle', element: <MarkaEkle /> },
            { path: 'marka-ekle/:id', element: <MarkaEkle /> }
          ]
        },
        {
          path: 'siparisyonetimi',
          children: [
            { path: 'siparisler', element: <Siparisler /> },
            { path: 'siparis-olustur', element: <SiparisOlustur /> },
            { path: 'terk-edilmis-siparisler', element: <TerkEdilmisSepetler /> },
            { path: 'siparis-ayarlari', element: <SiparisAyarlari /> }
          ]
        },
        {
          path: 'uyeyonetimi',
          children: [
            { path: 'uyeler', element: <Uyeler /> },
            { path: 'uye-gruplari', element: <UyeGruplari /> },
            { path: 'uye-ayarlari', element: <UyeAyarlari /> },
            { path: 'uyegrubu-ekle', element: <UyeGrubuEkle /> }
          ]
        },

        {
          path: 'pazarlama',
          children: [
            //     { path: '/', element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'hediye-ceki', element: <HediyeCeki /> },
            { path: 'kampanyalar', element: <Kampanyalar /> },
            { path: 'eposta-pazarlama', element: <EpostaPazarlama /> },
            { path: 'sms-pazarlama', element: <SmsPazarlama /> }
          ]
        },

        {
          path: 'blog',
          children: [
            { path: '/', element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
            { path: 'categories', element: <Categories /> }
          ]
        },
        {
          path: 'raporlar',
          children: [{ path: '/', element: <Raporlar /> }]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const Tanimlamalar = Loadable(lazy(() => import('../pages/dashboard/urunler/Tanimlamalar')));
const TopluUrunIslemleri = Loadable(lazy(() => import('../pages/dashboard/urunler/TopluUrunIslemleri')));
const UrunAyarlari = Loadable(lazy(() => import('../pages/dashboard/urunler/UrunAyarlari')));
const YeniKullanici = Loadable(lazy(() => import('../pages/dashboard/urunler/YeniKullanici')));
const Varyantlar = Loadable(lazy(() => import('../pages/dashboard/urunler/Varyantlar')));
const VaryantTanimla = Loadable(lazy(() => import('../pages/dashboard/urunler/VaryantTanimla')));
const Kategoriler = Loadable(lazy(() => import('../pages/dashboard/urunler/Kategoriler')));
const KategoriEkle = Loadable(lazy(() => import('../pages/dashboard/urunler/KategoriEkle')));
const Markalar = Loadable(lazy(() => import('../pages/dashboard/urunler/Markalar')));
const MarkaEkle = Loadable(lazy(() => import('../pages/dashboard/urunler/MarkaEkle')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
