import { useSnackbar } from 'notistack5';

import { useParams, useLocation } from 'react-router-dom';
// material
import {
  Autocomplete,
  Card,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Button
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getProducts } from '../../../redux/slices/product';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------

export default function UyeGrubuEkle() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { name } = useParams();
  const isEdit = pathname.includes('edit');
  const { enqueueSnackbar } = useSnackbar();

  const users = [
    {
      id: 1,
      name: 'Rasim KARADENİZ'
    },
    {
      id: 2,
      name: 'Ahmet Berke Aydın'
    },
    {
      id: 3,
      name: 'Ebrar YAŞAR'
    },
    {
      id: 4,
      name: 'Zeynep Nisa KARABACAK'
    },
    {
      id: 5,
      name: 'Melisa ELDEM'
    },
    {
      id: 6,
      name: 'Muhammed Emir AYTEMİŞ'
    }
  ];
  const xml = [
    {
      id: 1,
      name: 'Marmara Grubu'
    },
    {
      id: 2,
      name: 'Akdeniz Grubu'
    },
    {
      id: 3,
      name: 'Karadeniz Grubu'
    },
    {
      id: 4,
      name: 'Doğu Anadolu Grubu'
    },
    {
      id: 5,
      name: 'İç Anadolu Grubu'
    },
    {
      id: 6,
      name: 'Ege Grubu'
    }
  ];
  const handleSubmit = () => {
    enqueueSnackbar('Kayıt Başarıyla Eklendi', { variant: 'success' });
  };
  return (
    <Page title="Ürün Ekle | Ekonokobi">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Üye Grubu Ekle ' : 'Üye Grubu Düzenle'}
          links={[
            {
              name: 'Üye Grupları',
              href: PATH_DASHBOARD.uyeyonetimi.uyegruplari
            },
            { name: !isEdit ? 'Üye Grubu Ekle' : name }
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <TextField fullWidth label="Üye Grubu Adı" sx={{ mb: 2 }} />
              <Autocomplete
                fullWidth
                multiple
                options={users}
                getOptionLabel={(option) => option.name}
                sx={{ mb: 2 }}
                renderInput={(params) => <TextField {...params} label="Kullanıcılar" placeholder="Kullancılar" />}
              />
              <Autocomplete
                fullWidth
                multiple
                options={xml}
                getOptionLabel={(option) => option.name}
                sx={{ mb: 2 }}
                renderInput={(params) => <TextField {...params} label="XML Grupları" placeholder="XML Grupları" />}
              />
              <FormControlLabel
                value="end"
                label="Tüm XML'leri alsın"
                labelPlacement="end"
                control={<Switch />}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Kaydet
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
